<template>
  <div>
    <div class="pc-wrap" style="position: relative">
      <Swiper
        ref="banner"
        class="banner"
        :style="{ height: bannerH + 'px' }"
        :options="bannerOptions"
      >
        <SwiperSlide
          v-if="stars.length"
          class="banner-item banner-earth pc-wrap"
        >
          <div class="pc-content">
            <div class="earth-title-box">
              <div class="earth-title-sub">链接润泽汇 一切皆有可能</div>
              <div class="earth-title">
                <span class="mr-20">赋能</span>
                <span class="mr-20 mark">链接</span>
                <span class="mr-20">利他</span>
                <span class="mark">共享</span>
              </div>
            </div>

            <Earth
              ref="earth"
              class="earth-box"
              :boxWidth="boxWidth"
              :dots="stars"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          v-for="(item, index) in banner"
          :key="index"
          class="banner-item img"
          :style="{ 'background-image': 'url(' + item.pic + ')' }"
          @click.native="$jump(item.url)"
        ></SwiperSlide>
      </Swiper>
      <div v-if="bannerSize > 1" class="swiper-pagination">
        <span
          v-for="(item, index) in bannerSize"
          :key="index"
          class="swiper-pagination-item"
          :class="{ on: bannerIndex == index }"
        ></span>
      </div>
    </div>
    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="pt-row acea-row">
          <div class="pt-item flex-con">
            <div class="pt-num">200<i class="iconfont">&#xe633;</i></div>
            <div>联盟企业</div>
          </div>
          <div class="pt-item flex-con">
            <div class="pt-num">20000<i class="iconfont">&#xe633;</i></div>
            <div>服务会员</div>
          </div>
          <div class="pt-item flex-con">
            <div class="pt-num">1000000<i class="iconfont">&#xe633;</i></div>
            <div>服务社团</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">信鸽平台</div>
        <div class="subtitle"></div>
        <div class="sj-box">
          <img
            src="https://img.runzehui.net/2022/10/14/8f669202210141338476284.jpg"
            alt=""
          />
          <div
            v-for="(row, index) in sjArr"
            :key="index"
            class="sj-row acea-row row-between"
          >
            <div
              v-for="(item, i) in row"
              :key="i"
              @click="$jump(item.url)"
              class="sj-item"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">信鸽商城</div>
        <div class="subtitle"></div>
        <div class="shop-box acea-row row-center-wrapper">
          <div class="shop-row acea-row">
            <router-link class="shop-item" to="/news/75">
              <div class="shop-img">
                <img src="@/assets/images/icon/icon-2-21.png" alt="" />
              </div>
              <div class="shop-name">企业福采</div>
            </router-link>
            <router-link class="shop-item light" to="/news/76">
              <div class="shop-img">
                <img src="@/assets/images/icon/icon-2-22.png" alt="" />
              </div>
              <div class="shop-name">企业集采</div>
            </router-link>
            <router-link class="shop-item" to="/news/21">
              <div class="shop-img">
                <img src="@/assets/images/icon/icon-2-23.png" alt="" />
              </div>
              <div class="shop-name">溯源商城</div>
            </router-link>
            <router-link class="shop-item light" to="/news/20">
              <div class="shop-img">
                <img src="@/assets/images/icon/icon-2-24.png" alt="" />
              </div>
              <div class="shop-name">润品商城</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">集成服务</div>
        <div class="subtitle"></div>
        <div class="jc-box acea-row row-between">
          <div
            v-for="(item, index) in jcArr"
            :key="index"
            class="jc-item"
            @click="$jump(item.url)"
          >
            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">专项服务</div>
        <div class="subtitle"></div>
        <div class="zx-box">
          <router-link class="flex-con zx-item" to="/news/53">
            <img src="@/assets/images/bg-3-01.jpg" alt="" />
          </router-link>
          <router-link class="flex-con zx-item" to="/news/52">
            <img src="@/assets/images/bg-3-02.jpg" alt="" />
          </router-link>

          <div class="fix right">
            <AsideNewsShort />
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">数字商业联盟合作伙伴</div>
        <div class="subtitle">ALLIANCE PARTNER</div>
        <img src="@/assets/images/bg-4.png" alt="" />
        <div
          class="partner-box"
          :class="{ taggle: taggle_show }"
          :style="{ height: partnerBoxH + 'px' }"
        >
          <div class="partner-wrapper" ref="partner">
            <div
              v-for="(item, index) in partner"
              :key="index"
              class="partner-img"
              :class="{ hover: item.url }"
              @click="$jump(item.url)"
              :title="item.url"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <div v-show="!taggle_show" class="taggle-more" @click="taggleShow">
            下拉了解更多<i class="iconfont">&#xe611;</i>
          </div>
          <div v-show="taggle_show" class="taggle-more" @click="taggleHide">
            收起更多<i class="iconfont">&#xe60c;</i>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag" style="padding: 40px 0 0">
      <div class="pc-content" style="max-width: 100%">
        <div class="title">会员服务</div>
        <div class="subtitle">共享空间共同富裕</div>
        <router-link to="/news/5">
          <img src="@/assets/images/bg-5-01.jpg" alt="" />
        </router-link>
      </div>
    </div>

    <div class="nav-scroll" :style="{ width: asideWith + 'px' }">
      <div class="nav-scroll-wrapper">
        <div
          v-for="(item, index) in scrollNavs"
          :key="index"
          class="nav-scroll-item"
          @click="navScrollTo(index)"
        >
          <i class="iconfont">&#xeb5e;</i>
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Earth from "@/components/earth/index";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import request from "@/utils/request";
import AsideNewsShort from "@/components/aside/newsShort";

export default {
  components: {
    Earth,
    Swiper,
    SwiperSlide,
    AsideNewsShort,
  },
  data() {
    return {
      bannerOptions: {
        on: {
          slideChangeTransitionStart: (e) => {
            this.bannerIndex = e.activeIndex;
          },
        },
      },
      bannerIndex: 0,
      bannerH: 500,
      boxWidth: 375,
      scrollNavs: [
        {
          top: 0,
          index: 0,
          title: "信鸽平台",
        },
        {
          top: 0,
          index: 1,
          title: "专项服务",
        },
        {
          top: 0,
          index: 2,
          title: "集成服务",
        },
        {
          top: 0,
          index: 3,
          title: "联盟合作伙伴",
        },
        // {
        //   top: 0,
        //   index: 3,
        //   title: "股东企业",
        // },
        {
          top: 0,
          index: 4,
          title: "会员服务",
        },
      ],
      asideWith: 0,
      proSwiperOptions: {
        slidesPerView: 2,
        centeredSlides: true,
        loop: true,
      },
      banner: [],
      stars: [],
      group: {},
      platform: [],
      platformIndex: 0,
      article: [],
      partner: [],
      partner_h5: [],
      partner_url: "",
      products: [],
      shareholders: [],
      taggle_show: false,
      partnerH: 0,
      partnerHDft: 216,
      partnerBoxH: 216,
      sjArr: [
        [
          {
            img: "https://img.runzehui.net/2022/08/20/9747e20220820100413165.png",
            title: "数字社交名片",
            url: "",
          },
          {
            img: "https://img.runzehui.net/2022/08/20/c4fec202208201004146490.png",
            title: "数字官网",
            url: "",
          },
          {
            img: "https://img.runzehui.net/2022/08/20/bb3b1202208201004131149.png",
            title: "数字社团",
            url: "",
          },
          {
            img: "https://img.runzehui.net/2022/08/20/20d56202208201004147490.png",
            title: "数字品牌",
            url: "",
          },
        ],
        [
          {
            img: "https://img.runzehui.net/2022/08/20/32e1b20220820100414563.png",
            title: "数字活动",
            url: "",
          },
          {
            img: "https://img.runzehui.net/2022/08/20/79b27202208201004135700.png",
            title: "数字简历",
            url: "",
          },
          {
            img: "https://img.runzehui.net/2022/08/20/d5279202208201004139871.png",
            title: "企业数字小程序",
            url: "",
          },
          {
            img: "https://img.runzehui.net/2022/08/20/515af202208201004138357.png",
            title: "数字营销",
            url: "",
          },
        ],
      ],
      jcArr: [
        {
          img: "https://img.runzehui.net/2022/08/20/dcfb5202208201333057408.png",
          title: "数字转型",
          url: "/news/46",
        },
        {
          img: "https://img.runzehui.net/2022/08/20/c59de202208201333054947.png",
          title: "SAAS工具赋能",
          url: "/news/47",
        },
        {
          img: "https://img.runzehui.net/2022/08/20/73b1e202208201333055994.png",
          title: "企业资源共享",
          url: "/news/48",
        },
        {
          img: "https://img.runzehui.net/2022/08/20/cb56f202208201333056627.png",
          title: "商品溯源",
          url: "/news/49",
        },
        {
          img: "https://img.runzehui.net/2022/08/20/7a63b202208201333055014.png",
          title: "数字化营销",
          url: "/news/51",
        },
        {
          img: "https://img.runzehui.net/2022/08/20/745af202208201333058755.png",
          title: "对外开发系统",
          url: "/news/55",
        },
      ],
    };
  },
  computed: {
    bannerSize() {
      let size = this.banner.length;
      if (this.stars && this.stars.length) {
        size++;
      }
      return size;
    },
  },
  created() {
    const dom = document.documentElement;
    const winW = dom.clientWidth;
    const winH = dom.clientHeight;
    this.bannerH = winH - 64;
    if (winW > winH) {
      this.boxWidth = Math.min(1100 - 200, winH);
    } else {
      this.boxWidth = winW;
    }

    this.asideWith = winW / 2 - 550;

    // if (typeof window.entryUrl === "undefined" || window.entryUrl === "") {
    //   window.entryUrl = location.href;
    // }
    // let url = /(Android)/i.test(navigator.userAgent)
    //   ? location.href
    //   : window.entryUrl;

    request.get("index").then((res) => {
      let data = res.data;
      this.banner = data.home_banner;
      this.stars = data.home_star_ring;

      this.partner = data.home_partner;

      setTimeout(() => {
        this.partnerH = this.$refs.partner.offsetHeight;
        if (this.partnerH < 216) {
          this.partnerHDft = this.partnerH;
          this.partnerBoxH = this.partnerH;
        }
      }, 3000);

      // setTimeout(() => {
      //   this.group = data.home_group_profile[0];
      //   this.platform = data.home_platform;
      //   this.products = data.home_products;
      //   this.article = data.article.list;
      //   this.shareholders = data.home_shareholder;
      //   this.partner = data.home_partner;
      //   this.partner_h5 = data.home_H5_partner;
      //   if (data.home_partner_link[0]) {
      //     this.partner_url = data.home_partner_link[0].url;
      //   }
      // }, 300);

      if (this.stars && this.stars.length) {
        this.onVisibilityChange();
      }
    });
  },
  mounted() {
    request
      .get("wechat/config", { url: "https://www.runzehui.net/" })
      .then((res) => {
        let data = res.data;
        data.jsApiList = ["wx-open-launch-weapp"];
        data.openTagList = ["wx-open-launch-weapp"];
        console.log(data);
      });

    setTimeout(() => {
      this.setScrollNavs();
    });
  },
  methods: {
    taggleHide() {
      this.partnerBoxH = this.partnerHDft;
      this.taggle_show = false;
    },
    taggleShow() {
      this.partnerBoxH = this.partnerH;
      this.taggle_show = true;
    },
    setScrollNavs() {
      let doms = document.querySelectorAll(".scroll-tag");
      for (var i = 0; i < this.scrollNavs.length; i++) {
        this.scrollNavs[i].top = doms[this.scrollNavs[i].index].offsetTop;
      }
    },
    navScrollTo(i) {
      let top = this.scrollNavs[i].top;
      window.scrollTo({
        top: Math.max(top - 64, 0),
        behavior: "smooth",
      });
    },
    onVisibilityChange() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          this.$refs.earth.earthPause();
        } else {
          this.$refs.earth.earthPlay();
        }
      });
    },
    handleClickSlide(e, index) {
      const { url } = this.products[index];
      url && this.$jump(url);
    },
  },
};
</script>
<style scoped lang="scss">
.banner {
  height: 100vh;
  background: #090d32;
  overflow: hidden;
}
.swiper-pagination {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;

  .swiper-pagination-item {
    display: inline-block;
    width: 36px;
    height: 6px;
    background: #42404e;
    margin: 10px;

    &.on {
      background: #fff;
    }
  }
}

.banner-item {
  height: 100%;
  position: relative;

  &.img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.banner-earth {
  background: url("~@/assets/images/earth_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
}

.earth-title-box {
  position: absolute;
  top: 15%;
  z-index: 1;
}

.earth-title-sub {
  font-size: 36px;
  color: #fff;
  letter-spacing: 4px;
  margin-bottom: 20px;
}

.earth-title {
  font-size: 44px;
  letter-spacing: 6px;
  color: #fff;
  font-weight: 100;
  opacity: 0.7;

  .mark {
    color: #eb8d48;
  }
}

.earth-title-m {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;

  .mark {
    color: #f2ab3f;
  }

  .earth-subtitle {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 6px;
    margin-top: 10px;
  }
}

.earth-box {
  position: absolute;
  top: -10%;
  right: -15%;
}

.pt-row {
  padding: 0 20px;

  .pt-item {
    text-align: center;

    .pt-num {
      display: inline-block;
      position: relative;
      font-size: 32px;
      color: #000054;
      font-weight: bold;

      .iconfont {
        font-size: 18px;
        position: absolute;
        top: -4px;
        right: -22px;
      }
    }
  }
}

.box-serve {
  position: relative;
  .box-serve-item {
    display: flex;
  }
}

.fix {
  display: block;
  width: 200px;
  position: absolute;

  &.right {
    right: -20px;
    top: 0;
    transform: translateX(100%);
  }
}

@media (max-width: 1600px) {
  .fix {
    display: none;
  }

  .nav-scroll {
    .nav-scroll-wrapper {
      display: none;
    }
  }
}

.nav-scroll {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: flex-end;
  transform: translateY(-50%);

  .nav-scroll-wrapper {
    width: 200px;

    .nav-scroll-item {
      font-size: 16px;
      color: #707070;
      text-shadow: 0 0 1px #fff;
      padding: 6px;
      cursor: pointer;

      &:hover {
        color: #0f0963;
      }
    }
  }
}

.zx-box {
  display: flex;
  position: relative;
}

.gudong {
  img {
    width: 23.5%;
  }
}

.member-icons {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: -100px;
  padding-bottom: 30px;

  .member-icon {
    width: 140px;
    margin: 0 6px;
    padding: 30px 0;
    text-align: center;
    background: #fff;
    border: 1px solid #f5f5f5;
    font-size: 16px;
    color: #999;
    border-radius: 4px;

    &:hover {
      background: #100a64;
      color: #fff;
      border-color: #100a64;
      .iconfont {
        color: #fff;
      }
    }

    .iconfont {
      display: inline-block;
      font-size: 80px;
      margin-bottom: 20px;
      color: #100a64;
    }
  }
}

.partner-box {
  height: 216px;
  margin-top: 30px;
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;

  &.taggle {
    height: 432px;
  }

  .partner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .partner-img {
    width: auto;
    height: 84px;
    margin: 12px;
    border-radius: 10px;
    overflow: hidden;

    &.hover {
      cursor: pointer;
    }

    &:hover {
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    }

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}

.taggle-more {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 13px;
  line-height: 40px;
  text-align: center;
  color: #0f0a66;
  cursor: pointer;
  background: #fff;

  i.iconfont {
    font-size: 18px;
    margin-left: 10px;
  }
}

.intro {
  .intro-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .content {
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 2px;
    margin-bottom: 20px;
    width: 100%;
    white-space: pre-wrap;
  }

  video {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.link {
  font-size: 14px;
  color: $link;
  cursor: pointer;
}

.fuli {
  padding: 0 120px;
  .fuli-img {
    flex: 1.8;
    margin-right: 100px;
  }
  .fuli-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .fuli-btn {
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    padding: 16px 0;
    background: #fff;
    border-radius: 10px;

    &.on,
    &:hover {
      color: $link;
      box-shadow: $hover-shadow;
    }
  }
}

.hover {
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: $hover-shadow;
    position: relative;
    top: -2px;
  }
}

.pro-item {
  margin-left: 10px;
  border-radius: 10px;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0;
  }
}

.news-item {
  margin-left: 20px;
  padding: 20px 15px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0;
  }

  .news-cate {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .news-img {
    width: 100%;
    margin-bottom: 20px;
    background: #eee;
    border-radius: 10px;
    overflow: hidden;
  }

  .news-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .news-content {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.company-item {
  margin-left: 100px;

  &:first-of-type {
    margin-left: 0;
  }
}

.partner {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px dashed #eee;

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      width: auto;
      height: 80px;
      margin: 10px 15px;
    }
  }
}

.sj-box {
  .sj-row {
    margin-top: 14px;

    .sj-item {
      width: 264px;
      height: 178px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.shop-box {
  padding: 80px 0;
  background: url("~@/assets/images/bg-2-06.png") no-repeat;
  background-size: 100% auto;

  .shop-row {
    .shop-item {
      width: 250px;
      padding: 40px 0;
      font-size: 13px;
      text-align: center;
      color: #fff;
      background-color: rgba(0, 36, 171, 0.8);
      box-sizing: border-box;

      &.light {
        background-color: rgba(0, 113, 219, 0.9);
      }

      img {
        display: block;
        width: auto;
        height: 64px;
        margin: auto;
      }
    }
  }
}

.jc-box {
  .jc-item {
    width: 175px;
    cursor: pointer;
  }
}
</style>
